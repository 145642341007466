.page {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 2;
  overflow: hidden;
  min-height: 100vh;
}