.timeline {
  margin: 40px 0 30px 0;
  position: relative;

  @include media-max(580px) {
    flex-wrap: wrap;
  }

  &::after {
    content: '';
    width: 50%;
    height: 1px;
    background-image: linear-gradient(89deg, #e9b808 0%, #e9b808 40%, #8ed05a 60%, #8ed05a 100%);
    opacity: 0.5;
    bottom: 52px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }

  &__col {
    width: 126px;
  }

  &__box {
    box-shadow: inset 0 2px 5px rgba(76, 59, 0, 0.07);
    border-radius: 4px 4px 0 0;
    background-color: #f5f5f5;
  }

  &__procet {
    height: 120px;
    background: no-repeat url(../imgs/bg_arrow.png) center bottom;
    position: relative;

    h5 {
      color: #e9b808;
      font-family: Sansation;
      font-size: 4.7rem;
      font-weight: 700;
      line-height: 6.3rem;
      padding: 25px 0;
      text-align: center;
      position: absolute;
      bottom: 0;
      left:0;
      right: 0;
    }
  }

  &__time {
    height: 120px;
    border-radius: 4px 4px 0 0;
    background-color: #f0c43b;
    padding: 20px;
    margin-top: -20px;
  }

  &__bottom {
    border-radius: 0 0 4px 4px;
    background-color: #f0c43b;
    background-image: linear-gradient(-179deg, #fad359 0%, #f0c43b 100%);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 5px 5px 5px;
    margin-left: -5px;
    width: 100%;
    position: relative;

    &::after {
      content: '';
      width: 10px;
      right: -10px;
      height: calc(100% + 5px);
      background-color: #FFF;
      position: absolute;
      top: 0px;
      z-index: 0;
    }
  }

  &__col--2 {

    .timeline {

      &__procet {
        height: 165px;
        position: relative;

        &::before {
          content: '';
          width: 62px;
          height: 65px;
          background: #fbfbfb;
          background: -moz-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(251,251,251,1) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(252,252,252,1)), color-stop(100%, rgba(251,251,251,1)));
          background: -webkit-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(251,251,251,1) 100%);
          background: -o-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(251,251,251,1) 100%);
          background: -ms-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(251,251,251,1) 100%);
          background: linear-gradient(to bottom, rgba(252,252,252,1) 0%, rgba(251,251,251,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#fbfbfb', GradientType=0 );

          display: block;
          margin: 0 auto;
        }

        h5 {
          color: #73ba40;
        }
      }

      &__time {
        background-color: #73ba40;
        height: 75px;
      }

      &__bottom {
        background-color: #73ba40;
        background-image: linear-gradient(-179deg, #82d14b 0%, #73ba40 100%);
      }
    }
  }

  &__col--hours {
    width: 125px;
    height: 200px;
    background: no-repeat url(../imgs/bg_hours.png) top center;
    margin-top: 37px;

    @include media-max(580px) {
      margin-top: 31px;
      width: 36px;
    }
  }

  &__desc {
    text-align: center;
    margin-bottom: 10px;

    &-title {
      display: inline-block;
      vertical-align: middle;

      &::before {
        content: '';
        width: 18px;
        height: 18px;
        background: no-repeat url(../imgs/bg_time.png) center / cover;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -2px;
      }

      &--2 {
        margin-left: 60px;

        @include media-max(580px) {
          margin-left: 0;
        }

        &::before {
          background-image: url(../imgs/bg_time_2.png)
        }
      }

    }
  }
}