.header {
  background: no-repeat url(../imgs/bg_header.png) top center / cover;
  padding-top: 200px;
  padding-bottom: 100px;

  @include media-max(1500px) {
    background-position: top -100px right -200px;
  }

  @include media-max(991px) {
    background-position: top -150px right -100px;
  }

  @include media-max(767px) {
    background-position: top -42px right 0;
    background-size: 200% auto;
    padding-top: 72vw;
  }

  @include media-max(580px) {
    padding-top: 69vw;
  }

  &__logo {
    margin-top: 60px;
    margin-bottom: 5px;

    @include media-max(1500px) {
      margin-top: 0;
    }
  }

  &__title {
    margin-left: -3px;
    margin-bottom: 5px;
  }

  &__subtitle {
    color: #034798;
    font-size: 4rem;
  }

  &__tummy {
    margin-top: 20px;

    @include media-max(767px) {
      max-width: 30%;
      margin: 0 auto;
      display: block;
    }
  }

  &__box {
    &--1 {
      display: inline-block;
      vertical-align: top;
    }

    &--2 {
      display: inline-block;
      vertical-align: top;
      margin-left: -10px;

      @include media-max(1500px) {
        width: 350px;
      }

      @include media-max(991px) {
        margin-top: 180px;
      }

      @include media-max(767px) {
        margin-top: 20px;
        margin-left: 0;
        max-width: 100%;
      }
    }

    &--3 {
      display: inline-block;
      vertical-align: top;
      margin-left: -10px;

      @include media-max(1500px) {
        width: 100px;
        margin-left: 20px;
      }

      @include media-max(991px) {
        display: none;
      }

      @include media-max(767px) {
        display: block;
        width: 140px;
        margin: 0 auto;
      }
    }
  }
}