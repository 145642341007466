.info {

  &__container {
    background-color: #ebfbfe;
    padding: 60px 0 30px 0;

    @include media-max(767px) {
      padding: 20px 0 0 0;
    }
  }

  &::before {
    content: '';
    position: relative;
    width: 100%;
    height: 10vw;
    background: no-repeat url(../imgs/bg_info_top.png) top left;
    background-size: 100% 100%;
    min-height: 40px;
    display: block;
    margin-top: -6.5vw;
  }

  &::after {
    content: '';
    position: relative;
    width: 100%;
    height: 2.56vw;
    background: no-repeat url(../imgs/bg_info_bottom.png) top left;
    background-size: 100% 100%;
    min-height: 40px;
    display: block;
  }
}