@font-face {
	font-family:'VAGRoundedLTCom';
	src: url('../fonts/VAGRoundedLTCom-Thin.ttf');
	src: url('../fonts/VAGRoundedLTCom-Thin.eot');
	src: url('../fonts/VAGRoundedLTCom-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/VAGRoundedLTCom-Thin.woff') format('woff'),
	url('../fonts/VAGRoundedLTCom-Thin.svg#VAGRoundedLTCom-Thin') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 300;
}

@font-face {
	font-family:'VAGRoundedLTCom';
	src: url('../fonts/VAGRoundedLTCom-Light.ttf');
	src: url('../fonts/VAGRoundedLTCom-Light.eot');
	src: url('../fonts/VAGRoundedLTCom-Light.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/VAGRoundedLTCom-Light.woff') format('woff'),
		 url('../fonts/VAGRoundedLTCom-Light.svg#VAGRoundedLTCom-Light') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 400;
}
@font-face {
	font-family:'VAGRoundedLTCom';
	src: url('../fonts/VAGRoundedLTCom-Bold.ttf');
	src: url('../fonts/VAGRoundedLTCom-Bold.eot');
	src: url('../fonts/VAGRoundedLTCom-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/VAGRoundedLTCom-Bold.woff') format('woff'),
	url('../fonts/VAGRoundedLTCom-Bold.svg#VAGRoundedLTCom-Bold') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 700;
}

@font-face {
	font-family:'Sansation';
	src: url('../fonts/Sansation_Light.ttf');
	src: url('../fonts/Sansation_Light.eot');
	src: url('../fonts/Sansation_Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Sansation_Light.woff') format('woff'),
	url('../fonts/Sansation_Light.svg') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 300;
}

@font-face {
	font-family:'Sansation';
	src: url('../fonts/Sansation_Regular.ttf');
	src: url('../fonts/Sansation_Regular.eot');
	src: url('../fonts/Sansation_Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Sansation_Regular.woff') format('woff'),
		 url('../fonts/Sansation_Regular.svg') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 400;
}
@font-face {
	font-family:'Sansation';
	src: url('../fonts/Sansation_Bold.ttf');
	src: url('../fonts/Sansation_Bold.eot');
	src: url('../fonts/Sansation_Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Sansation_Bold.woff') format('woff'),
	url('../fonts/Sansation_Bold.svg') format('svg');
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
	font-weight: 700;
}