.about-product {
  background: no-repeat url(../imgs/bg_product.png) top center;
  background-size: 100% 100%;
  padding-top: 220px;
  padding-bottom: 80px;
  margin-top: -280px;

  @include media-max(1500px) {
    background-size: cover;
  }

  @include media-max(991px) {
    padding-top: 300px;
    margin-top: -380px;
  }

  &__3in1 {
    margin-top: -40px;

    @include media-max(991px) {
      margin-top: 0;
    }

    @include media-max(767px) {
      margin: 20px auto 0 auto;
      display: block;
      padding-left: 15px;
    }
  }

  &__col {
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;

    &--1 {
      width: 161px;

      @include media-max(543px) {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    &--2 {
      width: calc(100% - 165px);

      @include media-max(543px) {
        width: 100%;
        text-align: center;
        max-width: 280px;
        margin: 0 auto;
      }
    }
  }

  &__right {
    @include media-max(991px) {
      margin-top: 30px;
    }
  }

  &__fright {
    @include media-min(992px) {
      float: right;
    }

    @include media-max(991px) {
      img {
        margin: 0 auto;
        max-width: 80%;
        display: block;
      }
    }
  }
}